import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			pageName: "Финансовый модуль",
			desktopTitle: "Следи за деньгами",
			subTitle: (
				<p>
					Движение по&nbsp;счетам&nbsp;&mdash; это наглядное представление данных
					по&nbsp;финансовым потокам в&nbsp;бизнесе. Смотришь&nbsp;отчёт и сразу
					понимаешь, как идут дела.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/finance-presentation-by.png"
					alt="Финансовый модуль"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Қаржы модулі",
			desktopTitle: "Қаржыны бақылаңыз",
			subTitle: (
				<p>
					Шоттар бойынша қозғалыс - бұл бизнестегі қаржылық ағындар бойынша деректерді көрнекі ұсыну. Есепті қарайсыз да, істер қалай жүріп тұрғанын бірден түсінесіз.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/finance-presentation-kz.png"
					alt="Финансовый модуль"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			cta: pagesLinksKz.registration.text,
			ctaLink: pagesLinks.registration,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін",
		}
	}

	if (locale === 'ru-KZ') {
		return {
			pageName: "Финансовый модуль",
			desktopTitle: "Контролируй финансы",
			subTitle: (
				<p>
					Движение по&nbsp;счетам&nbsp;&mdash; это наглядное представление данных
					по&nbsp;финансовым потокам в&nbsp;бизнесе. Смотришь&nbsp;отчёт и сразу
					понимаешь, как идут дела.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/finance-presentation-kz.png"
					alt="Финансовый модуль"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
		}
	}

	return {
		pageName: "Финансовый модуль",
		desktopTitle: "Деньги любят счёт",
		subTitle: (
			<p>
				Движение по&nbsp;счетам&nbsp;&mdash; это наглядное представление данных
				по&nbsp;финансовым потокам в&nbsp;бизнесе. Смотришь отчёт и&nbsp;сразу
				понимаешь, как идут дела.
			</p>
		),
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/finance-presentation.png"
				alt="Финансовый модуль"
				objectFit="contain"
				objectPosition="center"
				placeholder="blurred"
				quality={90}
			/>
		),
		cta: pagesLinks.registration.text,
		ctaLink: pagesLinks.registration,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом.\nИ две недели бесплатно.",
	}
}
