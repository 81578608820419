import React from "react"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

import AppStore from "../../../assets/svg/_V2/AppStore"
import GooglePlay from "../../../assets/svg/_V2/GooglePlay"

import { externalLinks, pagesLinks } from "../common/links"

import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return [
			{
				title: "Держи фокус на результате",
				description: (
					<p>
						Отчёт &laquo;Движение по&nbsp;счетам&raquo; даёт полное представление
						о&nbsp;состоянии дел в&nbsp;бизнесе. Доходы и&nbsp;расходы представлены
						в&nbsp;виде развёрнутых отчётов по&nbsp;заведениям, счетам и&nbsp;типам
						оплат.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/finance-simple-feature-1-by.png"
						alt="движения по счетам"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "primary",
				reverse: true,

				actionLink: pagesLinks.registration.href,
				actionText: "Начать бесплатно",
			},
			{
				title: "Принимай товары на терминале",
				description: (
					<p>
						Сотрудник зала может сам вносить транзакции через терминал. Например,
						принимать товар на&nbsp;склад или оплачивать внезапный вызов сантехника.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/finance-simple-feature-2-by.png"
						alt="кассовый терминал"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature2,
				theme: "cyan",

				actionLink: pagesLinks.terminal.href,
				actionText: "Подробнее",
			},
			{
				category: "quick resto manager",
				title: <>Весь бизнес в&nbsp;твоих&nbsp;руках</>,
				description: (
					<>
						<p className={styles.feature3DescriptionLabel}>
							Приложение Quick Resto Manager &mdash; это удобное
							управление заведением со&nbsp;смартфона:
						</p>
						<ul>
							<li>
								мгновенная информация по наличным в&nbsp;кассе, выручке,
								среднему чеку и&nbsp;количеству гостей
							</li>
							<li>
								наглядные графики и&nbsp;сигналы о&nbsp;сомнительных операциях
							</li>
						</ul>
					</>
				),
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRMRoistat}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<AppStore />
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<GooglePlay />
						</a>
					</div>
				),
				img: (
					<div className={styles.image3__container}>
						<StaticImage
							className={classNames(styles.image3, styles.image__by)}
							src="./assets/finance-simple-feature-3-by.png"
							alt="quick resto manager"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				simpleAction: true,
				reverse: true,
				className: styles.feature3,
				theme: "green",

				actionText: "Подробнее",
				actionLink: pagesLinks.indexQRM.href,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: <>Нәтижеге қарай<br /> қозғалыңыз</>,
				tabletTitle: 'Нәтижеге қарай қозғалыңыз',
				mobileTitle: 'Нәтижеге қарай қозғалыңыз',
				description: (
					<p>
						«Шоттар бойынша қозғалыс» есебі бизнестегі істер жағдайы туралы толық түсінік береді. Кірістер
						мен шығыстар мекемелер, шоттар және төлемдер түрлері бойынша толық есептер түрінде ұсынылған.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/finance-simple-feature-1-kz.png"
						alt="движения по счетам"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "primary",
				reverse: true,

				actionLink: pagesLinks.registration.href,
				actionText: "Тегін бастау",
			},
			{
				title: "Тауарларды терминалда қабылдаңыз",
				description: (
					<p>
						Зал қызметкері терминал арқылы транзакцияларды өзі енгізе алады. Мысалы, тауарды қоймаға қабылдау
						немесе сантехникті кенеттен шақыруға ақы төлеу.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/finance-simple-feature-2-kz.png"
						alt="кассовый терминал"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature2,
				theme: "cyan",

				actionLink: pagesLinks.terminal.href,
				actionText: "Толығырақ",
			},
			{
				category: "quick resto manager",
				title: "Бизнес әрқашан қол астында",
				description: (
					<>
						<div className={styles.containerText}>
							<p className={styles.feature3DescriptionLabel}>
								Quick Resto Manager қосымшасы — бұл смартфоннан мекемені ыңғайлы басқару:
							</p>
							<ul>
								<li>
									кассадағы қолма-қол ақша, түсім, орташа чек және қонақтар саны бойынша лездік ақпарат
								</li>
								<li>
									көрнекі графиктер және күмәнді операциялар туралы сигналдар
								</li>
							</ul>
						</div>

					</>
				),
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRMRoistat}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<AppStore />
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<GooglePlay />
						</a>
					</div>
				),
				img: (
					<div className={styles.image3__container}>
						<StaticImage
							className={classNames(styles.image3, styles.image__kz)}
							src="./assets/finance-simple-feature-3-kz.png"
							alt="quick resto manager"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				simpleAction: true,
				reverse: true,
				className: styles.feature3,
				theme: "green",

				actionText: "Толығырақ",
				actionLink: pagesLinks.indexQRM.href,
			},
		]
	}

	if (locale === 'ru-KZ') {
		return [
			{
				title: <>Двигайся <br/>к результату</>,
				tabletTitle: 'Двигайся к результату',
				mobileTitle: 'Двигайся к результату',
				description: (
					<p>
						Отчёт &laquo;Движение по&nbsp;счетам&raquo; даёт полное представление
						о&nbsp;состоянии дел в&nbsp;бизнесе. Доходы и&nbsp;расходы представлены
						в&nbsp;виде развёрнутых отчётов по&nbsp;заведениям, счетам и&nbsp;типам
						оплат.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/finance-simple-feature-1-kz.png"
						alt="движения по счетам"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "primary",
				reverse: true,

				actionLink: pagesLinks.registration.href,
				actionText: "Начать бесплатно",
			},
			{
				title: "Принимай товары на терминале",
				description: (
					<p>
						Сотрудник зала может сам вносить транзакции через терминал. Например,
						принимать товар на&nbsp;склад или оплачивать внезапный вызов сантехника.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/finance-simple-feature-2-kz.png"
						alt="кассовый терминал"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature2,
				theme: "cyan",

				actionLink: pagesLinks.terminal.href,
				actionText: "Подробнее",
			},
			{
				category: "quick resto manager",
				title: "Бизнес всегда под рукой",
				description: (
					<>
						<div className={styles.containerText}>
							<p className={styles.feature3DescriptionLabel}>
								Приложение Quick Resto Manager &mdash; это удобное
								управление заведением со&nbsp;смартфона:
							</p>
							<ul>
								<li>
									мгновенная информация по наличным в&nbsp;кассе, выручке,
									среднему чеку и&nbsp;количеству гостей
								</li>
								<li>
									наглядные графики и&nbsp;сигналы о&nbsp;сомнительных операциях
								</li>
							</ul>
						</div>

					</>
				),
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRMRoistat}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<AppStore />
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<GooglePlay />
						</a>
					</div>
				),
				img: (
					<div className={styles.image3__container}>
						<StaticImage
							className={classNames(styles.image3, styles.image__kz)}
							src="./assets/finance-simple-feature-3-kz.png"
							alt="quick resto manager"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				simpleAction: true,
				reverse: true,
				className: styles.feature3,
				theme: "green",

				actionText: "Подробнее",
				actionLink: pagesLinks.indexQRM.href,
			},
		]
	}

	return [
		{
			title: "Держи фокус на результате",
			description: (
				<p>
					Отчёт &laquo;Движение по&nbsp;счетам&raquo; даёт полное представление
					о&nbsp;состоянии дел в&nbsp;бизнесе. Доходы и&nbsp;расходы представлены
					в&nbsp;виде развёрнутых отчётов по&nbsp;заведениям, счетам и&nbsp;типам
					оплат.
				</p>
			),
			img: (
				<StaticImage
					className={styles.image1}
					src="./assets/finance-simple-feature-1.png"
					alt="движения по счетам"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.feature1,
			theme: "primary",
			reverse: true,

			actionLink: pagesLinks.registration.href,
			actionText: "Начать бесплатно",
		},
		{
			title: "Делай проводки на терминале",
			description: (
				<p>
					Сотрудник зала может сам вносить транзакции через терминал. Например,
					принимать товар на&nbsp;склад или оплачивать внезапный вызов сантехника.
				</p>
			),
			img: (
				<StaticImage
					className={styles.image2}
					src="./assets/finance-simple-feature-2.png"
					alt="кассовый терминал"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.feature2,
			theme: "cyan",

			actionLink: pagesLinks.terminal.href,
			actionText: "Подробнее",
		},
		{
			category: "quick resto manager",
			title: "Бизнес у тебя в кармане",
			description: (
				<>
					<p>
						Приложение Quick Resto Manager&nbsp;&mdash; это удобное управление
						заведением со&nbsp;смартфона:
					</p>
					<ul>
						<li>
							мгновенная информация по&nbsp;наличным в&nbsp;кассе, выручке,
							среднему чеку и&nbsp;количеству гостей
						</li>
						<li>
							наглядные графики и&nbsp;сигналы о&nbsp;сомнительных операциях
						</li>
					</ul>
				</>
			),
			bottomContent: (
				<div className={styles.notes}>
					<a
						href={externalLinks.appstoreQRMRoistat}
						className={classNames(styles.note, styles.link)}
						target="_blank"
					>
						<AppStore />
					</a>
					<a
						href={externalLinks.googlePlayQRM}
						className={classNames(styles.note, styles.link)}
						target="_blank"
					>
						<GooglePlay />
					</a>
				</div>
			),
			img: (
				<div className={styles.image3__container}>
					<StaticImage
						className={classNames(styles.image3, styles.image__ru)}
						src="./assets/finance-simple-feature-3.png"
						alt="quick resto manager"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			simpleAction: true,
			reverse: true,
			className: styles.feature3,
			theme: "green",

			actionText: "Подробнее",
			actionLink: pagesLinks.indexQRM.href,
		},
	]
}

