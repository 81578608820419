import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { SolidTabsProps } from "../../../components/_V2/SolidTabs"

import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			items: [
				{
					tabName: "Счета",
					desktopTitle: "Распределяй деньги по счетам",
					mobileTitle: <>Распределяй <br/>деньги <br/>по счетам</>,
					tabletTitle: "Распределяй деньги по счетам",
					desktopContent: (
						<>
							Создай свои счета для управления денежными потоками.
							<br />В любой момент будет понятно, сколько денег в банке и сколько
							наличных.
						</>
					),
					tabletContent:
						"Создай свои счета для управления денежными потоками. В любой момент будет понятно, сколько денег в банке и сколько наличных.",
					mobileContent:
						"Создай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных. ",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-1-by.png"}
								alt={"финансовые счета"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Статьи",
					desktopTitle: "Организуй порядок",
					desktopContent: (
						<>
							Структурируй учёт прихода и расхода, создавая собственные группы статей.
							<br />
							От покупки хозтоваров и коммунальных платежей до представительских расходов.
						</>
					),
					tabletContent:
						"Структурируй учёт прихода и расхода — создай собственные группы статей. От покупки хозтоваров и коммунальных платежей до представительских расходов.",
					mobileContent:
						"Структурируй учёт прихода и расхода — создай собственные группы статей. От покупки хозтоваров и коммунальных платежей до представительских расходов.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-2-by.png"}
								alt={"статьи расходов"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Транзакции",
					desktopTitle: "Держи внимание на главном",
					desktopContent: (
						<>
							Транзакции – это основа для построения отчётов.
							<br />В любой момент доступна история движения между счетами.
						</>
					),
					tabletContent:
						"Транзакции – это основа для построения отчётов. В любой момент доступна история движения \n" +
						"между счетами.",
					mobileContent:
						"Транзакции – это основа для построения отчётов. В любой момент доступна история движения между счетами.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-3-by.png"}
								alt={"транзакции денежных средств"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			items: [
				{
					tabName: "Шоттар",
					desktopTitle: "Ақшаны шоттар бойынша үлестіріңіз",
					mobileTitle: <>Ақшаны шоттар<br />бойынша <br />үлестіріңіз</>,
					tabletTitle: "Ақшаны шоттар бойынша үлестіріңіз",
					desktopContent: (
						<>
							Ақша ағындарын басқару үшін өз шоттарыңызды жасаңыз. <br />
							Банкте қанша ақша және қанша қолма-қол ақша бар екені кез келген сәтте түсінікті болады.
						</>
					),
					tabletContent:
						"Ақша ағындарын басқару үшін өз шоттарыңызды жасаңыз. Банкте қанша ақша және қанша қолма-қол ақша бар екені кез келген сәтте түсінікті болады. ",
					mobileContent:
						"Ақша ағындарын басқару үшін өз шоттарыңызды жасаңыз. Банкте қанша ақша және қанша қолма-қол ақша бар екені кез келген сәтте түсінікті болады. ",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-1-kz.png"}
								alt={"финансовые счета"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Баптар",
					desktopTitle: "Тәртіпті ұйымдастыр",
					desktopContent: (
						<>
							Кіріс және шығысты есепке алуды құрылымдаңыз - өз баптар топтарыңызды жасаңыз. <br />
							Шаруашылық тауарларды сатып алудан және коммуналдық төлемдерден өкілдіктік шығыстарға дейін.
						</>
					),
					tabletContent:
						"Кіріс және шығысты есепке алуды құрылымдаңыз - өз баптар топтарыңызды жасаңыз. Шаруашылық тауарларды сатып алудан және коммуналдық төлемдерден өкілдіктік шығыстарға дейін.",
					mobileContent:
						"Кіріс және шығысты есепке алуды құрылымдаңыз - өз баптар топтарыңызды жасаңыз. Шаруашылық тауарларды сатып алудан және коммуналдық төлемдерден өкілдіктік шығыстарға дейін.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-2-kz.png"}
								alt={"статьи расходов"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Транзакциялар",
					desktopTitle: "Бастыға назар аударыңыз",
					desktopContent: (
						<>
							Транзакциялар - бұл есептерді құрастыру негізі. <br />
							Кез келген сәтте шоттар арасында қозғалыс тарихы қолжетімді.
						</>
					),
					tabletContent:
						"Транзакциялар - бұл есептерді құрастыру негізі. Кез келген сәтте шоттар арасында қозғалыс тарихы қолжетімді.",
					mobileContent:
						"Транзакциялар - бұл есептерді құрастыру негізі. Кез келген сәтте шоттар арасында қозғалыс тарихы қолжетімді.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-3-kz.png"}
								alt={"транзакции денежных средств"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	if (locale === 'ru-KZ') {
		return {
			items: [
				{
					tabName: "Счета",
					desktopTitle: "Распределяй деньги по счетам",
					mobileTitle: <>Распределяй <br/>деньги <br/>по счетам </>,
					tabletTitle: "Распределяй деньги по счетам",
					desktopContent: (
						<>
							Создай свои счета для управления денежными потоками.
							<br />В любой момент будет понятно, сколько денег в банке и сколько
							наличных.
						</>
					),
					tabletContent:
						"Создай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных.",
					mobileContent:
						"Создай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-1-kz.png"}
								alt={"финансовые счета"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Группировка",
					desktopTitle: "Организуй порядок",
					desktopContent: (
						<>
							Структурируй учёт прихода и расхода, создавая собственные группы статей.
							<br />
							От покупки хозтоваров и коммунальных платежей до представительских расходов.
						</>
					),
					tabletContent:
						"Структурируй учёт прихода и расхода — создай собственные группы статей. От покупки хозтоваров \n" +
						"и коммунальных платежей до представительских расходов.",
					mobileContent:
						"Структурируй учёт прихода и расхода — создай собственные группы статей. От покупки хозтоваров и коммунальных платежей до представительских расходов.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-2-kz.png"}
								alt={"статьи расходов"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Транзакции",
					desktopTitle: "Фокусируйся на главном",
					mobileTitle: <>Фокусируйся на&nbsp;главном</>,
					tabletTitle: "Фокусируйся на главном",
					desktopContent: (
						<>
							Транзакции — это основа для построения отчётов. <br/>
							История движений между счетами доступна в любой момент.
						</>
					),
					tabletContent:
						"Транзакции – это основа для построения отчётов. В любой момент доступна история движения \n" +
						"между счетами.",
					mobileContent:
						"Транзакции – это основа для построения отчётов. В любой момент доступна история движения между счетами.",
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src={"./assets/finance-feature-3-kz.png"}
								alt={"транзакции денежных средств"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	return {
		items: [
			{
				tabName: "Счета",
				desktopTitle: "Распределяй и властвуй",
				desktopContent: (
					<>
						Создай свои счета для управления денежными потоками.
						<br />В любой момент будет понятно, сколько денег в банке и сколько
						наличных.
					</>
				),
				tabletContent:
					"Создай свои счета для управления денежными потоками. В любой момент будет понятно, сколько денег в банке и сколько наличных.",
				mobileContent:
					"Создай свои счета для управления денежными потоками. В любой момент будет понятно, сколько денег в банке и сколько наличных.",
				mediaContent: (
					<div className={styles.image__container}>
						<StaticImage
							className={styles.image}
							src={"./assets/finance-feature-1.png"}
							alt={"финансовые счета"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Статьи",
				desktopTitle: "Поддерживай порядок",
				desktopContent: (
					<>
						Структурируй учёт прихода и расхода — создай собственные группы
						статей.
						<br />
						От покупки хозтоваров и коммунальных платежей до представительских
						расходов.
					</>
				),
				tabletContent:
					"Структурируй учёт прихода и расхода — создай собственные группы статей. От покупки хозтоваров и коммунальных платежей до представительских расходов.",
				mobileContent:
					"Структурируй учёт прихода и расхода — создай собственные группы статей. От покупки хозтоваров и коммунальных платежей до представительских расходов.",
				mediaContent: (
					<div className={styles.image__container}>
						<StaticImage
							className={styles.image}
							src={"./assets/finance-feature-2.png"}
							alt={"статьи расходов"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Транзакции",
				desktopTitle: "Всё внимание — главным вещам",
				desktopContent: (
					<>
						Транзакции – это основа для построения отчётов.
						<br />В любой момент доступна история движения между счетами.
					</>
				),
				tabletContent:
					"Транзакции – это основа для построения отчётов. В любой момент доступна история движения между счетами.",
				mobileContent:
					"Транзакции – это основа для построения отчётов. В любой момент доступна история движения между счетами.",
				mediaContent: (
					<div className={styles.image__container}>
						<StaticImage
							className={styles.image}
							src={"./assets/finance-feature-3.png"}
							alt={"транзакции денежных средств"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
		],
	}
}
