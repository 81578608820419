import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'
import Advantages from '../components/_V2/Advantages'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import BusinessTypes from '../components/_V2/BusinessTypes'

import ProductPresentation from '../pagesSections/finance/ProductPresentation'
import CRMFeatures from '../pagesSections/finance/CRMFeatures'
import IntegrationFeatures from '../pagesSections/finance/IntegrationFeatures'

import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'

import Presentation from '../pagesSections/finance/ProductPresentation/assets/finance-presentation.png'
import PresentationKz from '../pagesSections/finance/ProductPresentation/assets/finance-presentation-kz.png'
import PresentationBy from '../pagesSections/finance/ProductPresentation/assets/finance-presentation-by.png'

import { getData as advantagesSlides } from '../pages-data/_V2/finance/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { getData as featuresData } from '../pages-data/_V2/finance/features'
import { externalLinks } from '../pages-data/_V2/common/links'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/finance.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageFinance() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Финансовый модуль Quick Resto: наглядное представление данных по финансовым потокам в бизнесе',
    'ru-KZ': 'Финансовый модуль Quick Resto: наглядное представление данных по финансовым потокам в бизнесе | Казахстан',
    'kz-KZ': 'Финансовый модуль Quick Resto: наглядное представление данных по финансовым потокам в бизнесе | Казахстан',
    'ru-BY': 'Финансовый модуль Quick Resto: наглядное представление данных по финансовым потокам в бизнесе | Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}finance/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}finance/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}finance/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}finance/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Данные по финансовым операциям представлены в едином отчёте: приходные и расходные накладные, продажи, зарплата. Создавай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных.',
    'ru-KZ': 'Данные по финансовым операциям представлены в едином отчёте: приходные и расходные накладные, продажи, зарплата. Создавай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Данные по финансовым операциям представлены в едином отчёте: приходные и расходные накладные, продажи, зарплата. Создавай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Данные по финансовым операциям представлены в едином отчёте: приходные и расходные накладные, продажи, зарплата. Создавай свои счета для управления денежными потоками. В любой момент будет понятно сколько денег в банке и сколько наличных. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`,
  }

  const metaTags = [
    {
      name: 'viewport',
      content: 'width=375, maximum-scale=1.0, initial-scale=1',
    },
    {
      httpEquiv: 'X-UA-Compatible',
      content: 'ie=edge',
    },
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    // {
    //   name: 'keywords',
    //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
    // },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={currentTitle}
      schemaDescription={metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        className={classNames(
				  pageStyles.pageSection,
				  styles.finance__advantages,
        )}
        items={advantagesSlides(localizationContext.locale)}
      />

      <CRMFeatures className={pageStyles.pageSection} />

      <IntegrationFeatures className={pageStyles.pageSection} />

      <SimpleFeatures data={featuresData(localizationContext.locale)} className={classNames(pageStyles.pageSection, {[pageStyles.reports_by]: localizationContext.locale !== 'ru-RU'}, {[pageStyles.reports_ru]: localizationContext.locale === 'ru-RU'})} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
