import React from "react"
import styles from "./styles.module.scss"

import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return [
			{
				title: "Реальный баланс",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Наглядное представление прибыли и&nbsp;средств&nbsp;для поддержания оборотки. Данные доступны по&nbsp;каждой точке продаж.
						</p>
						<p className={styles.advantagesTablet}>
							Наглядное представление прибыли и&nbsp;средств&nbsp;для поддержания оборотки. Данные доступны по&nbsp;каждой точке продаж.
						</p>
					</>
				),
			},
			{
				title: "Контроль закупок",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Можно бегло взглянуть на цифры по&nbsp;статьям прихода и расхода&nbsp;&mdash; всё сразу ясно с закупочными ценами
							и&nbsp;точками генерации прибыли.
						</p>
						<p className={styles.advantagesTablet}>
							Можно бегло взглянуть на цифры по&nbsp;статьям прихода и расхода&nbsp;&mdash; всё сразу ясно с закупочными ценами
							и&nbsp;точками генерации прибыли.
						</p>
					</>
				),
			},
			{
				title: "Учтено всё",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Данные по финансовым операциям представлены в едином отчёте: приходные и расходные накладные, продажи, зарплата.
						</p>
						<p className={styles.advantagesTablet}>
							Данные по финансовым операциям представлены в едином отчёте: приходные и расходные накладные, продажи, зарплата.
						</p>
					</>
				),
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: "Нақты баланс",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Айналымды сақтап тұру үшін пайда мен қаражатты көрнекі
							ұсыну. Деректер әрбір сату нүктесі бойынша қолжетімді.
						</p>
						<p className={styles.advantagesTablet}>
							Айналымды сақтап тұру үшін пайда мен қаражатты көрнекі
							ұсыну. Деректер әрбір сату нүктесі бойынша қолжетімді.
						</p>
					</>
				),
			},
			{
				title: "Сатып алуды бақылау",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Кіріс және шығыс баптары бойынша цифрларды үстірт қарау сатып алу бағалары және пайданы өндіру нүктелері туралы түсінік береді.
						</p>
						<p className={styles.advantagesTablet}>
							Кіріс және шығыс баптары бойынша цифрларды үстірт қарау сатып алу бағалары және пайданы өндіру нүктелері туралы түсінік береді.
						</p>
					</>
				),
			},
			{
				title: "Барлығы ескерілген",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Қаржы операциялары бойынша деректер бірыңғай есепте ұсынылған: кіріс және шығыс жүкқұжаттары, сатулар, жалақы.
						</p>
						<p className={styles.advantagesTablet}>
							Қаржы операциялары бойынша деректер бірыңғай есепте ұсынылған: кіріс және шығыс жүкқұжаттары, сатулар, жалақы.
						</p>
					</>
				),
			},
		]
	}

	if (locale === 'ru-KZ') {
		return [
			{
				title: "Реальный баланс",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Наглядное представление прибыли
							и средств для поддержания
							оборотки. Данные доступны
							по&nbsp;каждой точке продаж.
						</p>
						<p className={styles.advantagesTablet}>
							Наглядное представление прибыли и&nbsp;средств для поддержания оборотки.
							Данные доступны по&nbsp;каждой точке продаж.
						</p>
					</>
				),
			},
			{
				title: "Контроль закупок",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Беглый взгляд на цифры по статьям
							прихода и расхода даёт
							представление о закупочных ценах
							и точках генерации прибыли.
						</p>
						<p className={styles.advantagesTablet}>
							Беглый взгляд на цифры по статьям прихода и расхода даёт представление
							о закупочных ценах и точках генерации прибыли.
						</p>
					</>
				),
			},
			{
				title: "Учтено всё",
				description: (
					<>
						<p className={styles.advantagesDesktop}>
							Данные по финансовым операциям
							представлены в едином отчёте:
							приходные и расходные накладные,
							продажи, зарплата.
						</p>
						<p className={styles.advantagesTablet}>
							Данные по финансовым операциям представлены в едином отчёте: приходные
							и расходные накладные, продажи, зарплата.
						</p>
					</>
				),
			},
		]
	}

	return [
		{
			title: "Реальный баланс",
			description: (
				<>
					<p className={styles.advantagesDesktop}>
						Наглядное представление прибыли
						и средств для поддержания
						оборотки. Данные доступны
						по каждой точке продаж.
					</p>
					<p className={styles.advantagesTablet}>
						Наглядное представление прибыли и средств для поддержания оборотки.
						Данные доступны по каждой точке продаж.
					</p>
				</>
			),
		},
		{
			title: "Контроль закупок",
			description: (
				<>
					<p className={styles.advantagesDesktop}>
						Беглый взгляд на цифры по статьям
						прихода и расхода даёт
						представление о закупочных ценах
						и точках генерации прибыли.
					</p>
					<p className={styles.advantagesTablet}>
						Беглый взгляд на цифры по статьям прихода и расхода даёт представление
						о закупочных ценах и точках генерации прибыли.
					</p>
				</>
			),
		},
		{
			title: "Учтено всё",
			description: (
				<>
					<p className={styles.advantagesDesktop}>
						Данные по финансовым операциям
						представлены в едином отчёте:
						приходные и расходные накладные,
						продажи, зарплата.
					</p>
					<p className={styles.advantagesTablet}>
						Данные по финансовым операциям представлены в едином отчёте: приходные
						и расходные накладные, продажи, зарплата.
					</p>
				</>
			),
		},
	]
}
