import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import classNames from "classnames";
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Проводки",
					desktopTitle: "Все финансы на виду",
					desktopContent:
						"Добавляй в Quick Resto приходные и расходные накладные, чтобы агрегировать все цифры по движению денег в бизнесе.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/finance-integration-1-by.png"
							alt="финансовые проводки"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: false,
				},
				{
					desktopContentWidth: 490,
					tabName: "Интеграции",
					desktopTitle: "Просто добавь накладную ",
					desktopContent:
						"Загрузи файл накладной из 1C или DocsInBox в бэк-офис, выбери счёт списания и дату. Мы обработаем файл и добавим транзакцию для использования в отчётах.",
					mobileContent:
						"Загрузи файл накладной из 1C или DocsInBox в бэк-офис, выбери счёт списания и дату. Мы обработаем файл \nи добавим транзакцию для использования в отчётах.",
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.integrationImage)}
							src="./assets/finance-integration-2.png"
							alt="интеграции quick resto"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: true,
				},
				{
					desktopContentWidth: 490,
					tabName: "Финансовый отчёт",
					desktopTitle: "Отчёт для профи, доступный каждому",
					desktopContent: (
						<>
							Финансовые данные по всему бизнесу — в стандартном бухглатерском формате: «Отчёте о прибылях и&nbsp;убытках».
						</>
					),
					tabletContent: (
						<>
							Финансовые данные по всему бизнесу — в стандартном бухглатерском формате: «Отчёте о прибылях и&nbsp;убытках».
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/finance-integration-3-by.png"
							alt="финансовый отчет"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: false,
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Өткізбелер",
					desktopTitle: "Бүкіл қаржы\nкөз алдында",
					desktopContent:
						"Бизнестегі ақшаның қозғалысы бойынша барлық цифрларды біріктіру үшін Quick Resto-ға кіріс және шығыс жүкқұжаттарын қосыңыз.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/finance-integration-1-kz.png"
							alt="финансовые проводки"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: false,
				},
				{
					desktopContentWidth: 490,
					tabName: "Интеграциялар",
					desktopTitle: "Жай ғана жүкқұжатты енгізіңіз",
					desktopContent:
						"Жүкқұжат файлын 1С-тен немесе DocsInBox-тан бэк-офиске жүктеңіз, есептен шығару шотын және күнін таңдаңыз. Біз файлды өңдейміз де, есептерде пайдалану үшін транзакцияны қосамыз.",
					mobileContent:
						"Жүкқұжат файлын 1С-тен немесе DocsInBox-тан бэк-офиске жүктеңіз, есептен шығару шотын және күнін таңдаңыз. Біз файлды өңдейміз де, есептерде пайдалану үшін транзакцияны қосамыз.",
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.integrationImage)}
							src="./assets/finance-integration-2.png"
							alt="интеграции quick resto"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: true,
				},
				{
					desktopContentWidth: 490,
					tabName: "Қаржылық есеп",
					desktopTitle: "Әркімге қолжетімді кәсіпқойларға арналған есеп",
					desktopContent: (
						<>
							Бүкіл бизнес бойынша қаржылық деректер - стандартты бухгалтерлік форматта: «Пайда мен шығындар туралы есепте».
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/finance-integration-3-kz.png"
							alt="финансовый отчет"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: false,
				},
			],
		}
	}

	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Проводки",
					desktopTitle: "Все финансы \n" +
						"перед глазами",
					desktopContent:
						"Добавляй в Quick Resto приходные и расходные накладные, чтобы агрегировать все цифры по движению денег в бизнесе.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/finance-integration-1-kz.png"
							alt="финансовые проводки"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: false,
				},
				{
					desktopContentWidth: 490,
					tabName: "Интеграции",
					desktopTitle: "Просто внеси накладную",
					desktopContent:
						"Загрузи файл накладной из 1C или DocsInBox в бэк-офис, выбери счёт списания и дату. Мы обработаем файл и добавим транзакцию для использования в отчётах.",
					mobileContent:
						"Загрузи файл накладной из 1C или DocsInBox в бэк-офис, выбери счёт списания и дату. Мы обработаем файл \nи добавим транзакцию для использования в отчётах.",
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.integrationImage)}
							src="./assets/finance-integration-2.png"
							alt="интеграции quick resto"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: true,
				},
				{
					desktopContentWidth: 490,
					tabName: "Финансовый отчёт",
					desktopTitle: "Отчёт для профи, доступный каждому",
					desktopContent: (
						<>
							Финансовые данные по всему бизнесу — в стандартном бухглатерском формате: «Отчёте о прибылях и&nbsp;убытках».
						</>
					),
					tabletContent: (
						<>
							Финансовые данные по всему бизнесу — в стандартном бухглатерском формате: «Отчёте о прибылях и&nbsp;убытках».
						</>
					),

					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/finance-integration-3-kz.png"
							alt="финансовый отчет"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					isPatternHidden: false,
				},
			],
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 460,
				tabName: "Проводки",
				desktopTitle: "Смотри на все финансы сразу",
				desktopContent:
					"Добавляй в Quick Resto приходные и расходные накладные, чтобы агрегировать все цифры по движению денег в бизнесе.",
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/finance-integration-1.png"
						alt="финансовые проводки"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				isPatternHidden: false,
			},
			{
				desktopContentWidth: 460,
				tabName: "Интеграции",
				desktopTitle: "Просто добавь накладную ",
				desktopContent:
					"Загрузи файл накладной из 1C или DocsInBox в бэк-офис, выбери счёт списания и дату. Мы обработаем файл и добавим транзакцию для использования в отчётах.",
				mediaContent: (
					<StaticImage
						className={classNames(mediaContent.image, mediaContent.integrationImage)}
						src="./assets/finance-integration-2.png"
						alt="интеграции quick resto"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				isPatternHidden: true,
			},
			{
				desktopContentWidth: 460,
				tabName: "Финансовый отчёт",
				desktopTitle: "Отчёт для профи доступный каждому",
				desktopContent: (
					<>
						Финансоые данные по всему бизнесу представлены в стадартном
						бухглатерском формате — <br/>«Отчёт о прибылях и убытках».
					</>
				),
				tabletContent: (
					<>
						Финансоые данные по всему бизнесу представлены в стадартном
						бухглатерском формате — «Отчёт о прибылях и убытках».
					</>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/finance-integration-3.png"
						alt="финансовый отчет"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				isPatternHidden: false,
			},
		],
	}
}
