import React, {useEffect} from "react"
import SolidTabs, { SOLID_TABS_THEMES } from "../../../components/_V2/SolidTabs"
import { getData }  from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function CRMFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.items;

	useEffect(() => {
		features = data.items;
	}, [data]);

	return (
		<SolidTabs
			className={props.className}
			items={features}
			theme={SOLID_TABS_THEMES.PURPLE_LIGHT}
			transformOnAdaptive={true}
		/>
	)
}
